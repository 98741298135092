if ( window.location.hash) {
  var hash = window.location.hash;
  if(jQuery(hash).length) {
    scroll(0, 0);

    setTimeout(function () {
      scroll(0, 0);
    }, 1);

    jQuery('html, body').animate({
      scrollTop: jQuery(hash).offset().top - jQuery('header').innerHeight()
    }, 2000);
  }
}

var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
jQuery(document).ready(function () {
  jQuery('.toggle-navigation').on('click', function (event) {
    jQuery('body').toggleClass('navigation-open');
    event.preventDefault();
  });

  var headerOffset = jQuery('header').innerHeight();

  jQuery('.hero .scroll').on('click', function (event) {
    jQuery('html, body').animate({
      scrollTop: jQuery('.intro').offset().top - headerOffset
    }, 2000);
    event.preventDefault();
  });

  jQuery('a.scroll').on('click', function (event) {
    jQuery('html, body').animate({
      scrollTop: jQuery('.intro').offset().top - headerOffset
    }, 2000);
    event.preventDefault();
  });

  var floorPlans = [];

  if (jQuery('#floor-plan-list').length) {
    jQuery('#floor-plan-list .floor-plan').each(function () {
      var $item = jQuery(this);
      floorPlans.push({
        src: $item.find('a').attr('href'),
        title: $item.find('a').attr('title'),
        visible: $item.is(':visible') ? true : false
      });
    });
  }

  jQuery('.floor-plan-list .floor-plan a').on('click', function (event) {
    var $floorPlanItem = jQuery(this).parent();
    var index = $floorPlanItem.index();

    jQuery.magnificPopup.open({
      items: floorPlans.filter(function (floorPlan) {
        return floorPlan.visible === true;
      }),
      type: 'image',
      gallery: {
        enabled: true
      },
      image: {
        verticalFit: false
      }
    }, index);

    event.preventDefault();
  });

  jQuery('.floor-plans .view-more').on('click', function (event) {
    jQuery('.floor-plan-list .floor-plan').show();
    jQuery(this).hide();

    floorPlans.forEach(function (floorPlan) {
      floorPlan.visible = true;
    });
  });

  if (jQuery('#gallery').length) {
    jQuery('.gallery-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slide-arrow slide-prev"><img src="/wp-content/themes/olentangy/assets/images/icons/arrow-prev.png" alt=""></button>',
      nextArrow: '<button type="button" class="slide-arrow slide-next"><img src="/wp-content/themes/olentangy/assets/images/icons/arrow-next.png" alt=""></button>'
    });
  }

  jQuery('.navbar a.local, footer .navigation .local').on('click', function (event) {
    var location = jQuery(this).attr('href');
    var section = location.substring(location.indexOf('#'));
    if (jQuery(section).length) {
      jQuery('html, body').animate({
        scrollTop: jQuery(section).offset().top - headerOffset
      }, 1500);
      jQuery('body').removeClass('navigation-open');
      event.preventDefault();
    }
  });

  var date = new Date();

  var datePicker = jQuery('#date').pickadate({
    format: 'mm-dd-yyyy',
    //max: [date.getFullYear(), date.getMonth(), date.getDate()],
    min: [date.getFullYear(), date.getMonth(), date.getDate()],
    //max: new Date(2018, 1, 15)
    onStart: function () {

    },
    onClose: function () {

    },
    onSet: function(context) {
      jQuery('#date').focus();
    }
  });

  jQuery.formUtils.addValidator({
    name: 'phone_us',
    validatorFunction: function (value, $el, config, language, $form) {
      var regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
      return regex.test(value);
    },
    errorMessage: 'Phone number is not valid',
    errorMessageKey: 'badPhoneNumber'
  });

  var $signUpForm = jQuery('#sign-up-for-updates form');

  if (jQuery('#sign-up-form').length) {
    jQuery.validate({
      form: '#sign-up-form',
      borderColorOnError: '#ff554c',
      scrollToTopOnError: false,
      onSuccess: function ($form) {
        $signUpForm.find('.spinner').addClass('requesting');
        $signUpForm.find('input[type="submit"]').prop('disabled', true);
        jQuery.ajax({
          url: 'process-sign-up.php',
          type: 'POST',
          dataType: 'json',
          data: $form.serialize()
        }).done(function (response) {
          $signUpForm.find('.spinner').removeClass('requesting');
          if (response.status === 'success') {
            $signUpForm.find('.response-message').text(response.message).fadeTo('slow', 1);
            $signUpForm.find('input[type="submit"]').prop('disabled', false);
            $signUpForm[0].reset();
          }
        });
        return false;
      }
    });
  }

  var $contactForm = jQuery('.contact-us form');

  if (jQuery('#contact-form').length) {
    jQuery.validate({
      form: '#contact-form',
      borderColorOnError: '#ff554c',
      scrollToTopOnError: false,
      onSuccess: function ($form) {
        $contactForm.find('.spinner').addClass('requesting');
        $contactForm.find('button[type="submit"]').prop('disabled', true);
        jQuery.ajax({
          url: na.ajaxurl,
          type: 'POST',
          dataType: 'json',
          data: $form.serialize()
        }).done(function (response) {
          $contactForm.find('.spinner').removeClass('requesting');
          if (response.status === 'success') {
            window.location.href = "/thank-you";
            // $contactForm.find('.response-message').text(response.message).fadeTo('slow', 1);
            // $contactForm.find('button[type="submit"]').prop('disabled', false);
            // $contactForm[0].reset();
          }
        });
        return false;
      }
    });
  }

  var hash = window.location.hash;
  if (hash !== '') {
    if (jQuery(hash).length) {
      jQuery('html, body').animate({
        scrollTop: jQuery(hash).offset().top - headerOffset
      }, 1500);
    }
  }

  // Headlines Slider
  if (jQuery('.hero .headlines-slider').length) {
    jQuery('.hero .headlines-slider').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      cssEase: 'linear'
    });
  }

  // Availability
  jQuery('.availability .see-available-units a').on('click', function (event) {
    var $floorPlan = jQuery(this).closest('.item');
    $floorPlan.find('.available-units').toggle();
    event.preventDefault();
  });

  jQuery('#available-only').on('change', function () {
    if (jQuery(this).prop('checked') === true) {
      jQuery('.availability .contact-for-details').hide();
      jQuery('.availability .see-available-units').show();
    } else {
      jQuery('.availability .contact-for-details').show();
      jQuery('.availability .see-available-units').hide();
      jQuery('.availability .available-units').hide();
    }
  });

  jQuery('.availability .item .image').magnificPopup({
    delegate: 'a',
    type: 'image',
    gallery: {
      enabled: true
    }
  });


  var map;

  jQuery(window).on('load', function () {
    var OlentangyMap = {
      lat: 40.057277,
      lng: -83.041634
    };

    if (jQuery('#google-map-temp').length) {
      var position = {lat: OlentangyMap.lat, lng: OlentangyMap.lng};

      map = new google.maps.Map(document.getElementById('google-map'), {
        zoom: 17,
        center: position,
        scrollwheel: false,
      });

      var contentString = '<a href="https://www.google.com/maps/place/Olentangy+Commons/@40.0572331,-83.043154,17z/data=!3m1!4b1!4m5!3m4!1s0x88388da98b934f19:0x36d42277e2dfcc80!8m2!3d40.0572302!4d-83.0416064" target="_blank">The Commons at Olentangy</a>';
      //Set window width + content
      var infowindow = new google.maps.InfoWindow({
        content: contentString,
        maxWidth: 500
      });

      var marker = new google.maps.Marker({
        position: position,
        map: map,
      });

      google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
      });

      google.maps.event.addDomListener(window, 'resize', function () {
        var center = map.getCenter();
        google.maps.event.trigger(map, 'resize');
        map.setCenter(position);
      });
    }
  });

    var scrollTopPosition = 0;
    var $discoverAvenues = jQuery('#discover-avenues');
    var discoverAvenuesTopPosition = $discoverAvenues.offset().top;
    var windowHeight = jQuery(window).height();

    $discoverAvenues.addClass('appear');

    jQuery(window).on('scroll', function () {
        scrollTopPosition = jQuery(window).scrollTop();
        if (scrollTopPosition > 300) {
            if (!jQuery('header').hasClass('shrink')) {
                jQuery('header').addClass('shrink');
            }
        } else {
            if (jQuery('header').hasClass('shrink')) {
                jQuery('header').removeClass('shrink');
            }
        }

        // if(scrollTopPosition > (discoverAvenuesTopPosition - (windowHeight / 2))) {
        //     if(!$discoverAvenues.hasClass('appear')) {
        //         $discoverAvenues.addClass('appear');
        //     }
        // } else {
        //     if($discoverAvenues.hasClass('appear')) {
        //         $discoverAvenues.removeClass('appear');
        //     }
        // }
    });

  jQuery(window).on('resize', function () {
    windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    headerOffset = jQuery('header').innerHeight();
  });

  if(jQuery('.intro-video-wrapper').length) {
    videoCapable();
  }

});

function videoCapable() {
  var mp4Source = jQuery('.hero').data('videomp4');
  var webmSource = jQuery('.hero').data('videowebm');

  var $introVideoWrapper = jQuery('.intro-video-wrapper');
  var video = document.createElement('video');
  video.autoplay = true;
  video.muted = true;
  video.preload = 'auto';
  video.loop = true;

  var videoSourceMp4 = document.createElement('source');
  videoSourceMp4.src = mp4Source;
  videoSourceMp4.type = 'video/mp4';

  var videoSourceWebm = document.createElement('source');
  videoSourceWebm.src = webmSource;
  videoSourceWebm.type = 'video/webm';

  video.appendChild(videoSourceMp4);
  video.appendChild(videoSourceWebm);

  video.addEventListener('canplaythrough', function () {
    if (this.readyState > 3 && this.paused === true) {
      this.play();
    }
  });

  if (windowWidth >= 768) {
    $introVideoWrapper.append(video);
  }
}